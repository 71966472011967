import { useContext } from "react";
import { SessionContext } from "../../contexts/sessionContext";
import "./Seats.scss";

type SeatsProps = {
  order: (string | null)[];
};

export const Seats = ({ order }: SeatsProps) => {
  const { sessionId, backendUrl, displayUser, authData: {user} } = useContext(SessionContext);

  // @ts-ignore
  const seated = order.includes(user?.uid);

  const chooseSeat = async (seat: number) => {
    await fetch(`${backendUrl}/takeSeat`, {
      method: "POST",
      body: JSON.stringify({
        seat,
        sessionId,
        token: await user?.getIdToken(),
      })
    }).then(res => res.json());
  };

  return (
    <div>
      <span>{order.includes(String(user?.uid)) ? "seat chosen" : "Choose a seat (play direction is left to right)"}</span>
      <ul className="seats">
        {order.map((player, i) => (
          <li
            key={`${player}-${i}`}
            className={`seat ${player || seated ? "taken" : ""}`}
            onClick={
              !player
                ? () => {
                  chooseSeat(i);
                }
                : undefined
            }
          >
            {displayUser(player || String(i+1))}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Seats;
