import { useContext } from "react";
import { Game, SessionContext } from "../contexts/sessionContext";

export const SessionOutcome = ({game}: {game: Game}) => {
  const {displayUser} = useContext(SessionContext);

  console.log(game.outcomeSummary);
  
  return (<div>
    {game?.status.winner && <div> {displayUser(game?.status.winner)} won the game {game?.outcomeSummary.howWon === "outright" ? "outright " : `on counts with a count of ${game?.outcomeSummary.lowest}`}</div>}
    {game?.outcomeSummary.howWon === "drawn" && <div> {game?.outcomeSummary.players.map(displayUser).join(", ")} drew the game with an equal count of {game?.outcomeSummary.lowest}</div>}
    {game?.outcomeSummary.howWon === "blocked" && <span>game was blocked with no winner {displayUser(game.outcomeSummary.players[0])} had the lowest count with {game.outcomeSummary.lowest}</span>}
  </div>);
};