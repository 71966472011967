import { useContext } from "react";
import { SessionContext } from "../../../contexts/sessionContext";
import Divider from "@mui/material/Divider";
import { Domino } from "../../../components/Domino";

export const Timeline  = () => {
  const {session, displayUser, game, pack} = useContext(SessionContext);
  const lastRoundofPlays = game?.PlayEvents.slice(game.PlayEvents.length - game.numberOfPlayers).map(playEvent => {
    return {
      userId: playEvent.userId,
      numbers: playEvent.cardId ? pack?.find(({id}) => id === playEvent.cardId)?.numbers : null
    };
  });
  const getUsersLastPlayedNumbers = (participant: string) => lastRoundofPlays?.find(({userId}) => userId === participant)?.numbers;  
  
  return (
    <ol className="game-timeline">
      {(session?.participants || []).map(participant => (
        <li key={participant} className={`${game?.status.nextPlayer === participant? "current": ""}`}>
          <div style={{display: "flex", justifyContent: "space-between", height: "28px"}}>
            <span style={{
              "flexShrink": "1",
              "textOverflow": "ellipsis",
              fontWeight: game?.status.nextPlayer === participant ? "bold": "normal",
              overflow: "hidden"}}> {`${displayUser(participant)}`}</span>
            {getUsersLastPlayedNumbers(participant) === null ? "pass" : <Domino customClass="rotate-90" vals={getUsersLastPlayedNumbers(participant) || []} />}
          </div>
          <Divider />
        </li>
      ))}
    </ol>
  );
};