import { useContext } from "react";
import { SessionContext } from "../contexts/sessionContext";
import { SessionOutcome } from "./SesssionOutcome";

export const GameStatus = () => {
  const { game, sessionId, pack, displayUser, authData: {user} } = useContext(SessionContext);

  const shuffled = game?.status.stateName === "shuffled";
  const started = game?.status.stateName === "started";
  const handLength = pack?.filter(
    ({ player }) => player === user?.uid
  )?.length;
  const needsCards = (handLength !== undefined) && handLength < Number(game?.numberOfCards);
  const seated = game?.order.includes(String(user?.uid));
  const nextPlayer = displayUser(String(game?.status.nextPlayer));

  return (
    <div>
      {!sessionId && "join or start a new session" }
      {sessionId && shuffled &&
      (
        seated && !needsCards
          ? "Waiting for others to pull and get seated"
          : "get seated and pull your cards"
      )
      }
      {started && ((nextPlayer === "you" ? "your": `${nextPlayer}'s`) + " turn")}
      {game?.status.stateName === "ended" && <SessionOutcome game={game} />}
    </div>
  );
};
