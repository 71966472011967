import { useContext } from "react";
import { FECard, SessionContext } from "../../contexts/sessionContext";
import { Domino } from ".././Domino";
import Button from "@mui/material/Button";
import "./Handv2.scss";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface HandProps {
  setSelectedCard: (card: FECard) => void;
  chooseEnd: (card: number[]) => void
  pass: () => void;
  selectedCard: FECard | null;
}
export const Handv2 = ({setSelectedCard, pass, selectedCard}: HandProps) => {
  const {pack, session, ready, authData: {user}} = useContext(SessionContext);

  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  return (
    <>
      {session?.nextGame && !session.ready.includes(String(user?.uid)) && <Button onClick={ready}>Go to next game</Button>}
      {session?.ready.includes(String(user?.uid)) && "waiting for others to start the next game"}
      <Button onClick={pass} variant="contained" size="large" >Pass</Button> 
      <div className="hand-container" ref={animationParent}>
        {pack?.filter(card => (card.player === user?.uid) && (card.played === false)).map(card => (
          <Domino highlight={card.id === selectedCard?.id} vals={card.numbers} key={card.id} click={() => setSelectedCard(card)} />
        ))}
      </div>
    </>
  );
};