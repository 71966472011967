
import Button from "@mui/material/Button";
// @ts-ignore
import { ReactComponent as UpChevron } from "../../../img/chevron-down-circle.svg";
// @ts-ignore
import { ReactComponent as RightChevron } from "../../../img/chevron-right-circle.svg";
import "./styles.scss";


export const GoToBottom =   ({...props}: Record<string, unknown>) => (
  <span className="to-bottom" >
    <Button {...props} >
      <UpChevron fill="currentColor" className="icon mobile"/>
      <RightChevron fill="currentColor" className="icon desktop"/>
    </Button>
  </span>
);
