import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";



import { getApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBLMJW6Tay5gLXTdY2Q4N5_9jDtR5T3BdY",
  authDomain: "noes-246.firebaseapp.com",
  projectId: "noes-246",
  storageBucket: "noes-246.appspot.com",
  messagingSenderId: "576880813921",
  appId: "1:576880813921:web:776aae6b9b60efe0234720",
  measurementId: "G-CTKBDLJD40",
};
// Initialize Firebase
initializeApp(firebaseConfig);

if(process.env.NODE_ENV === "development"){
  const auth = getAuth(); 
  connectAuthEmulator(auth, "http://localhost:9099");
  // firebaseApps previously initialized using initializeApp()
  // @ts-ignore
  if(window.Cypress) {
    initializeFirestore(getApp(), { experimentalForceLongPolling: true });
  }
  const db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
}



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
