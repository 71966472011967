export function scrollBottom({
  element,
  force,
  test,
  ...optionals
}: {
  element: Element;
  force?: boolean;
  test?: boolean;
  noAnim?: boolean;
}) {
  let doScroll = !!force;
  const clientHeight = element.clientHeight;
  const scrollTop = element.scrollTop;
  const scrollHeight = element.scrollHeight;
  const lastMessage = element.querySelector(".message:last-of-type");

  doScroll = !doScroll
    ? clientHeight + scrollTop + (lastMessage?.clientHeight || 0) + 15 >= scrollHeight
    : doScroll;
  console.log("doScroll", doScroll);
  console.log({ clientHeight, scrollTop, scrollHeight });

  if (doScroll && !test) {
    element.scrollTo({
      top: scrollHeight,
      behavior: optionals.noAnim ? "auto" : "smooth",
    });

    return doScroll;
  } else if (test) {
    return doScroll;
  }
}
