import { useContext, useRef } from "react";
import { SessionContext } from "../../contexts/sessionContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import "./SessionSummary.scss";

export const SessionSummary = () => {
  const diaRef = useRef<HTMLDialogElement>(null);
  const {session, sessionId, displayUser, game} = useContext(SessionContext);

  const nextPlayer = displayUser(String(game?.status.nextPlayer));
  const started = game?.status.stateName === "started";

  return (
    <div>
      <dialog id="favDialog" ref={diaRef}>
        <form method="dialog">
          <h3>{session?.name}</h3>
          <span>session id {sessionId}</span>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <h2 className="form-header">Score ({session?.games.length} games)</h2>
            {session?.participants.map( participant => (
              <div key={participant}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={`${displayUser(participant)}: ${session.winners.filter(thisWinner => thisWinner === participant).length}`}
                  />
                </ListItem>
                <Divider component="li" />
              </div>
            ))}
            

          </List>
          <div>
            <Button color="error" variant="outlined" type="submit" value="cancel">Close</Button>
          </div>
        </form>
      </dialog>
      <p style={{cursor: "pointer"}}>
        <span id="updateDetails" onClick={() => {
          if (typeof diaRef.current?.showModal === "function") {
            diaRef.current.showModal();
          } else {
            // ...
          }
        }}>{session?.name} (game {session?.games.length})</span>
      </p>
      <p>{started && ((nextPlayer === "you" ? "your": `${nextPlayer}'s`) + " turn")}</p>
      <output></output>

    </div>
  );
};