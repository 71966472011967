import Button from "@mui/material/Button";
// @ts-ignore
import { ReactComponent as UpChevron } from "../../../img/chevron-up-circle.svg";
// @ts-ignore
import { ReactComponent as LeftChevron } from "../../../img/chevron-left-circle.svg";
import "./styles.scss";


export const GoToTop =   ({...props}: Record<string, unknown>) => (
  <span className="to-top" >
    <Button {...props} >
      <UpChevron fill="currentColor" className="icon mobile" />
      <LeftChevron fill="currentColor" className="icon desktop" />
    </Button>
  </span>
);
