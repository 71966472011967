import { useContext, useRef, useState } from "react";
import { SessionContext } from "../../contexts/sessionContext";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Fab from "@mui/material/Fab";
import LoadingButton from "@mui/lab/LoadingButton";
// import "./SessionSummary.scss";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import {ReactComponent as AddIcon} from "../../img/plus.svg";

import "./StartSessionv2.scss";

export const StartSessionv2 = () => {
  const diaRef = useRef<HTMLDialogElement>(null);
  const {setSessionId, backendUrl, authData: {user}} = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  
  return (
    <div className="start-session">
      <dialog id="favDialog" ref={diaRef} data-test="new-session-modal">
        
        <form
          method="dialog"
          onSubmit={async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const sessionName = formData.get("session-name") as string;
            const numberOfPlayers = Number(formData.get("no-of-players"));
            const missingNoe = formData.get("missing-noe");
            console.log(...formData.entries());
              
            if (!sessionName) return;
            setLoading(true);
            const res = await fetch(`${backendUrl}/createSession`, {
              method: "POST",
              body: JSON.stringify({
                sessionName, 
                token: await user?.getIdToken(),  
                posingDomino: [6,6],
                posingPlayer: null,
                numberOfPlayers,
                blockedTieResolution: ["noWinner"],
                missingNoe,
              }),
              // headers: {
              //   "content-type": "application/json"
              // }
            }).then(res => res.json())
              .catch(() =>  setLoading(false));
            setLoading(false);
            if(res.status === "success"){
              setSessionId(res.sessionId);
              nav("/game");
            }
          }}
        >
          <h2>Start a new session</h2>
          <TextField margin="normal" fullWidth label="Session Name" type="text" id="session-name" name="session-name" />
          <FormControl fullWidth>
            <InputLabel id="no-of-players-label">Number of Players</InputLabel>
            <Select
              native
              labelId="no-of-players-label"
              id="no-of-players"
              name="no-of-players"
              label="number of players"
              defaultValue={4}
            >
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="missing-noe">Missing Domino</InputLabel>
            <Select
              native
              labelId="missing-noe"
              id="missing-noe"
              name="missing-noe"
              label="missing domino"
              defaultValue={"0:0"}
            >
              <option value={"0:0"}>0:0</option>
              <option value={"random"}>random</option>
            </Select>
          </FormControl>
          <div className="actions">
            <LoadingButton loading={loading} type="submit" variant="contained">Start Session</LoadingButton>
            <Button variant="outlined" type="button" value="cancel" color="error" onClick={() => {
              diaRef.current?.close();
            }}>Close</Button>
          </div>
        </form>
          
      </dialog>
      <Fab data-test="new-session-fab" variant="extended" color="primary" aria-label="add" onClick={() => {
        diaRef.current?.showModal();
      }}>
        < AddIcon />
      </Fab>
      {/* <output></output> */}

    </div>
  );
};