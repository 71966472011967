import "./Domino.scss";

interface DominoProps {
  vals: number[];
  click?: () => void;
  gone?: boolean;
  customClass?: string;
  highlight?: boolean
}

export const Domino = (props: DominoProps) => {
  const { vals, click, gone, customClass = "", highlight } = props;
  const valsExist = vals && vals.length === 2;

  const topArray = valsExist ? new Array(vals[0]).fill(0) : [];
  const bottomArray = valsExist ? new Array(vals[1]).fill(0) : [];

  return (
    <div
      className={`domino ${highlight ? "highlight": ""} ${gone ? "domino-gone" : ""} ${customClass}`}
      onClick={click}
    >
      <div className="domino-side">
        {topArray.map((val, i) => {
          return (
            <div key={i} className={`dot-${vals[0]}`}>
              <div className="dot"></div>
            </div>
          );
        })}
      </div>
      {valsExist ? <div className="side-seperator"></div> : null}
      <div className="domino-side">
        {bottomArray.map((val, i) => {
          return (
            <div key={i} className={`dot-${vals[1]}`}>
              <div className="dot"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};