import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import "./Login.scss";

export const Login = () => {
  let action = "signIn";
  // const [error, setError] = useState("");
  const { signUp, signIn, user, error, signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) navigate("/");
  }, [user, navigate]);

  return (
    <div className="content">
      <form className="login-form" onSubmit={async e => {
        console.log(action);
        
        e.preventDefault();
        try {
          const formData = new FormData(e.target as HTMLFormElement);
          const email = formData.get("email") as string;
          const password = formData.get("password") as string;
          if(!email || !password) return;
          
          if (action === "signUp") {
            await signUp(email, password);
          } else {
            await signIn(email, password);
          }
          // navigate("/");
        } catch (error) {
          console.log(error);
          // @ts-ignore
          // setError(error);
        }
        
      }}>
        <h1 style={{textAlign: "center"}}>Bajan Noez</h1>
        <Button onClick={signInWithGoogle} color="error" sx={{backgroundColor: "#dc3545", textTransform: "none", fontWeight: "bold"}} variant="contained">Sign in with Google</Button>
        <div className="separator">or</div>
        <h4 className="form-header">Email and password</h4>
        <TextField required label="email" type="email" id="email" name="email" />
        <TextField required label="password" type="password" id="password" name="password" />
        {/* @ts-ignore */}
        {error && <Alert data-test="login-error" severity="error">{error.message}</Alert>}
        <div className="action-buttons">
          <Button data-test="login" type="submit" variant="outlined" onClick={() => {action = "signIn"; console.log("action", action);}}>Login</Button> 
          <Button data-test="signup" type="submit" variant="outlined" onClick={() => {action = "signUp"; console.log("action", action);}}>Sign Up</Button> 
        </div>
      </form>
    </div>
  );
};

export default Login;