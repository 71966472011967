import React, { useContext, useState } from "react";
// import { Profile } from "../components/Profile";
import { SessionContext } from "../contexts/sessionContext";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";

import "./StartSession.scss";
import { useNavigate } from "react-router-dom";

const StartSession = () => {
  const { setSessionId, backendUrl, sessions, authData: {user, signOutFB} } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const joinSession = async (sessionId: string) => {
    if (!sessionId) return;
    setLoading(true);
    const res = await fetch(`${backendUrl}/joinSession`, {
      method: "POST",
      body: JSON.stringify({
        sessionId, 
        token: await user?.getIdToken()
      }),
    }).then(res => res.json())
      .catch(() =>  setLoading(false));
    setLoading(false);
    if(res.status === "success"){
      setSessionId(sessionId);
      nav("/game");
    }
  };


  return (
    <div className="session-conainer" >

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.target as HTMLFormElement);
          const sessionId = formData.get("session-id") as string;
          joinSession(sessionId);
        }}
      >
        <Button data-test="main-logout" onClick={signOutFB}>Logout</Button>
        <h2>Join a session</h2>
        <TextField margin="normal" fullWidth label="Session ID" type="text" id="session-id" name="session-id" />
        <LoadingButton loading={loading} type="submit" variant="contained">Join Session</LoadingButton>
      </form>
      <List>
        <h2 className="form-header">Most recent sessions</h2>
        {sessions.map(session => (
          <React.Fragment key={session.id}>
            <ListItem data-test="session-list-item" alignItems="flex-start" secondaryAction={<LoadingButton data-test="join-session-button" loading={loading} onClick={() => joinSession(session.id)}>Join</LoadingButton>}>
              <ListItemText
                primary={session.name}
                secondary={session.id}
              />
              <Divider absolute component="div" />
            </ListItem>
          </React.Fragment>
          // ...
        ))}
      </List>
    </div>
  );
};

export default StartSession;
