import { createContext, ReactNode, useState } from "react";
import {
  Timestamp,
} from "firebase/firestore";
import { useConnectToCurrentGameData, useConnectToSessionData, useGetUsersInfo } from "./collectionHandlers";
import { useAuth } from "../hooks/useAuth";
import { Auth, getAuth, User } from "firebase/auth";


export interface Card {
  numbers: number[];
  player: string | null;
  played: boolean;
  openSides?: number[];
}

export interface FECard extends Card {
  id: string;
}

export interface Event {
  action: string;
  userId: string;
  timestamp: Timestamp;
}

export interface CardPlayedEvent extends Event {
  cardId: (string | null);
}

export type AnyEvent = Event | CardPlayedEvent;

export interface Game {
  played: Card[];
  status: {
    stateName: "shuffled" | "started" | "ended";
    winner?: string;
    nextPlayer?: string | null;
  };
  sessionId: string;
  numberOfCards: number;
  numberOfPlayers: number;
  poser?: string;
  posingDomino?: number[];
  updatedAt: Timestamp;
  order: (string | null)[];
  outcomeSummary: {
    howWon: string;
    players: string[];
    lowest: number;
    winner: string;
  };
  players: string[];
  PlayEvents: CardPlayedEvent[];
}

export type BlockedTieResolutionType = "noWinner" | "rePull" | "previousWinner" | "leastCards" | "leastPasses";

export interface Session {
  participants: string[];
  name: string;
  order: (string | null)[];
  rules: {
    posingDomino: number[] | null;
    posingPlayer: string | null;
    numberOfPlayers: number;
    numberOfCards: number;
    blockedTieResolution: BlockedTieResolutionType[];
  };
  currentGame: string | null;
  nextGame: string | null;
  messages: {text: string; from: string; fromId: string}[];
  games: string[];
  outcomeSummary?: {
    howWon: string;
    players: string[];
    lowest: number;
    winner: string;
  };
  winners: string[];
  ready: string[];
}

export interface SessionWithId extends Session {
  id: string;
}

export const SessionContext = createContext<{
  game?: Game | null;
  sessionId?: string | null;
  session?: Session | null;
  pack?: FECard[]| null;
  sessions: SessionWithId[];
  setSessionId: (sessionId: string | null) => void;
  currentGameId: string | null;
  backendUrl: string;
  displayUser: (userId: string) => string;
  ready: () => void;
  authData: {
    user: User | null;
    error: null;
    signUp: (email: string, password: string) => Promise<void>;
    signIn: (email: string, password: string) => Promise<void>;
    auth?: Auth;
    signOutFB: () => Promise<void>;
  }
    // todo: i need to find a better way to let this know setSessionId will always be set
    }>({
      setSessionId: () => null, 
      game: null,
      sessionId: null,
      session: null,
      pack: null,
      currentGameId: null,
      backendUrl: "",
      sessions: [],
      displayUser: () => "",
      ready: () => null,
      authData: {
        user: null,
        error: null,
        signUp: async () => undefined,
        signIn: async () => undefined,
        // @ts-ignore
        // auth: getAuth(window.FBApp),
        signOutFB: async () => undefined,
      },
    });

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  
  // ids that we use to keep track of the state of things
  const [sessionId, setSessId] = useState<string | null>(window.localStorage.getItem("sessionId"));
  const authData = useAuth();
  
  const {session, currentGameId, sessions, sessionUnsub} = useConnectToSessionData({sessionId, userId: authData.user?.uid});
  const {game, pack} = useConnectToCurrentGameData({currentGameId, userId: authData.user?.uid || null});
  const users = useGetUsersInfo({ids: game?.players});

  const displayUser =  (userId: string) => {
    if (authData.user?.uid === userId) return "you";
    const foundUser = users?.find(({uid}) => userId === uid);
    const display = foundUser?.displayName || foundUser?.email || userId;
    return display;
  };

  const setSessionId = (sessionId: string | null) => {
    setSessId(sessionId);
    if(sessionId) {
      window.localStorage.setItem("sessionId", sessionId);
    } else {
      // TODO: FIXME: I SHOULD CHECK TO SEE IF I NEED TO UNDO ANY LISTENERS HERE
      sessionUnsub && sessionUnsub();
      window.localStorage.removeItem("sessionId");
    }
  };

  
  const ready = async () => {
    const user = getAuth().currentUser;

    fetch(`${backendUrl}/nextGameReady`, {
      method: "POST",
      body: JSON.stringify({
        token: await user?.getIdToken(),
        sessionId
      })
    });
  };

  
  const backendUrl = (process.env.NODE_ENV === "development") ? "http://127.0.0.1:5001/noes-246/us-central1" : "https://us-central1-noes-246.cloudfunctions.net";

  return (
    <SessionContext.Provider value={{ authData, ready, sessions, displayUser, sessionId, setSessionId, game, session, pack, currentGameId, backendUrl }}>
      {children}
    </SessionContext.Provider>
  );
};
