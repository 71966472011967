import { useContext } from "react";
import { Card, FECard, SessionContext } from "../../contexts/sessionContext";
import { Domino } from "../Domino";
import "./Boardv2.scss";

interface BoardProps {
  chooseEnd: (end: number[]) => void;
  selectedCard: FECard | null;
}

export const getRotationAngle = (
  array: Card[],
  index: number
) => {
  if(array.length === 0 || !array){
    return 0;
  }
  const [top, bottom] = array[index].numbers;
  if (top === bottom) return 0;
  if (array[index + 1] && array[index + 1].numbers.includes(top)) {
    return 90;
  } else if (array[index - 1] && array[index - 1].numbers.includes(top)) {
    return -90;
  } else if (array[index + 1] && array[index + 1].numbers.includes(bottom)) {
    return -90;
  } else if (array[index - 1] && array[index - 1].numbers.includes(bottom)) {
    return 90;
  }
};

export const Boardv2 = ({chooseEnd}: BoardProps) => {
  const { game } = useContext(SessionContext);

  // TODO: :put this in a context or some shared state
  const hasStickyEnds = (array: Card[]) => array.length > 2;

  const stickyEndsFilter = (card: Card, index: number, array: Card[]) => !hasStickyEnds(array) || ((index !== 0) && (index !== array.length - 1));
  
  return (
    <>
      <div className={`board ${hasStickyEnds(game?.played || []) ? "bg" : ""}`}>
        {game &&
              <>
                {game.played.filter(stickyEndsFilter).map(({ numbers }, index) => (
                  <Domino
                    key={`${numbers[0]}-${numbers[1]}`}
                    vals={numbers}
                    customClass={`rotate-${getRotationAngle(
                      game.played,
                      // because we cut off the start of the array in our filter function we will need to offset this 
                      // value so it will have its correct position in the game.played array which is not filtered
                      index + (!hasStickyEnds(game.played) ? 0 : 1)
                    )}`}
                    click={() => {
                      if(!hasStickyEnds(game.played)) {
                        chooseEnd(numbers);
                      }
                    }}
                  />
                ))}
              </>}
      </div>
    </>
  );
};

