import { InputAdornment, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useEffect, useRef, useState } from "react";
import "./Chat.scss";
import { SessionContext } from "../contexts/sessionContext";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { scrollBottom } from "../util/helpers";

const Chat = () => {
  const [sending, setSending] = useState(false);
  const { sessionId, session, backendUrl, authData: {user}, displayUser } = useContext(SessionContext);
  const formRef = useRef<HTMLFormElement>(null);
  const messageRef = useRef<HTMLDivElement | null>(null);
  const shouldScroll = useRef<boolean>(true);


  useEffect(() => {
    // INVESTIGATE: for some reason this hook fires after the newest message has already been added to the DOM
    if(messageRef.current) {
      shouldScroll.current = !!scrollBottom({element: messageRef.current});
    }
  }, [session?.messages]);


  return (
    <div className="game-chat">
      <header>CHAT</header>
      <div className="messages" style={{flexGrow: 1}} ref={messageRef}>
        {session?.messages?.map((message, index) => {
          const groupPart = session?.messages?.[index - 1]?.from === message.from || session?.messages?.[index + 1]?.from === message.from;
          const groupStart = session?.messages?.[index - 1]?.from !== message.from;
          const fromMe = user?.uid === message.fromId;

          return (
            <div className={`message ${groupPart ? "group": ""} ${groupStart ? "group-start": ""} ${fromMe ? "me": ""}`} key={index}>
              {groupStart && <span className="sender">{displayUser(message.fromId || message.from)}</span>} {message.text}
            </div>
          );
        })}
      </div>
      <form ref={formRef} id="message-form" onSubmit={async (event) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        
        if(!form["message-box"].value) return;

        setSending(true);
        
        await fetch(`${backendUrl}/sendMessage`, {
          method: "POST",
          body: JSON.stringify({
            text: form["message-box"].value,
            sessionId,
            token: await user?.getIdToken(),
          })
        }).then(res => {
          res.json();
          if(res.ok){
            form.reset();
          }
        }).catch(err => console.log(err));
        setSending(false);

      }}>
        <TextField
          id="message-box"
          maxRows={4}
          placeholder="send message..."
          multiline
          fullWidth
          variant="standard"
          onKeyDown={(event) => {
            if(event.key === "Enter" && !event.shiftKey){
              event.preventDefault();
              formRef.current?.requestSubmit();
            }
          }
          }
          InputProps={
            {
              endAdornment:(<InputAdornment position="end">
                <LoadingButton
                  type="submit" size="small" loading={sending} sx={{padding: 0, minWidth: 0}}
                >
                  <SendIcon />
                </LoadingButton>
              </InputAdornment>)
            }
          }
        />
      </form>
    </div>
  );
};

export default Chat;