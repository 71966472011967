import { useContext, useEffect, useRef, useState } from "react";
import { Handv2 } from "../../components/Handv2.tsx/Handv2";
import { FECard, SessionContext } from "../../contexts/sessionContext";
import "./Gamev2.scss";
import { Boardv2, getRotationAngle } from "../../components/Boardv2/Boardv2";
import { GameStatus } from "../../components/GameStatus";
import { GameHeader } from "./GameHeader";
import Seats from "../../components/Seats/Seats";
import ShuffledPack from "../../components/ShuffledPack";
import { GoToTop } from "./controls/GoToTop";
import { GoToBottom } from "./controls/GoToBottom";
import { Timeline } from "./Timeline/Timeline";
import { Domino } from "../../components/Domino";
import Chat from "../../components/Chat";
import { Button, Dialog } from "@mui/material";

const Gamev2 = () => {
  const { sessionId, game, currentGameId, backendUrl, session, authData: { user } } = useContext(SessionContext);
  const [selectedCard, setSelectedCard] = useState<FECard | null>(null);
  const [hideleft, sethideleft] = useState(false);
  const [hideright, sethideright] = useState(false);
  const boardContRef = useRef<HTMLDivElement | null>(null);
  const [notifDialogOpen, setNotifDialogOpen] = useState(false);
  
  const notStarted = game?.status.stateName === "shuffled";
  
  const chooseEnd = async (end: number[]) => {
    await fetch(`${backendUrl}/playCard`, {
      method: "POST",
      body: JSON.stringify({
        end,
        cardId: selectedCard?.id,
        gameId: currentGameId,
        sessionId,
        token: await user?.getIdToken(),
      })
    }).then(() => setSelectedCard(null));
  };
  /**
 * this function sets the card that the user wants to play, if there are no cards on the board 
 * and this user is supposed to posed then it will go ahead and play that card, if not the user
 * will then choose and end in order to play
*/
  const playCard = async (card: FECard) => {
    const { id, numbers } = card;
    setSelectedCard(card);
    console.log("chosen", numbers);
    if ((game?.played.length === 0) && user && (game.status.nextPlayer === user.uid)) {
      await fetch(`${backendUrl}/playCard`, {
        method: "POST",
        body: JSON.stringify({
          cardId: id,
          gameId: currentGameId,
          token: await user?.getIdToken(),
        })
      }).then(res => res.json());
    }
  };

  const pass = async () => {
    await fetch(`${backendUrl}/passTurn`, {
      method: "POST",
      body: JSON.stringify({
        gameId: currentGameId,
        token: await user?.getIdToken(),
      })
    }).then(res => res.json());
  };

  const shrinkBoard = () => boardContRef.current?.classList.toggle("shrink-noes");

  const dbClickShrink = (ref: HTMLDivElement | null) => {
    if(!notStarted) {
      shrinkBoard();
      if (ref) {
        checkScrollers(ref);
      }
    }
  };

  const checkScrollers = (ref: HTMLDivElement) => {
    if (window.innerWidth > 768) {
      const scrollLeftMax = ref.scrollWidth - ref.clientWidth;
      if (ref.scrollLeft === 0) {
        sethideleft(true);
      } else {
        sethideleft(false);
      }
      if (scrollLeftMax <= 1) {
        sethideright(true);
      } else if (ref.scrollLeft < (scrollLeftMax - 2)) {
        sethideright(false);
      } else if (ref.scrollLeft > (scrollLeftMax - 2)) {
        sethideright(true);
      }
    } else {
      const scrollTopMax = ref.scrollHeight - ref.clientHeight;
      if (ref.scrollTop === 0) {
        sethideleft(true);
      } else {
        sethideleft(false);
      }
      if (scrollTopMax <= 1) {
        sethideright(true);
      } else if (ref.scrollTop < (scrollTopMax - 2)) {
        sethideright(false);
      } else if (ref.scrollTop > (scrollTopMax - 2)) {
        sethideright(true);
      }
    }
  };

  const liveRef = (ref: HTMLDivElement) => {
    if (ref) {
      boardContRef.current = ref;
      checkScrollers(ref);
      ref.addEventListener("scroll", () => {
        checkScrollers(ref);
      }, { passive: true });
    }
  };

  const acceptDialog = () => {
    setNotifDialogOpen(false);
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification("Your turn!");
        notification.onclick = () => {
          window.focus();
        };
      }
    });
  };

  const denyDialog = () => {
    // set local storage to not show the dialog again
    localStorage.setItem("notifDialog", "false");
    setNotifDialogOpen(false);
  };

  const promptUserForTurn = async () => {
    if (game?.status.nextPlayer === user?.uid) {
      // check if we have notification permissions and send a notification if we do
      if (Notification.permission === "granted") {
        const notification = new Notification("Your turn!", {
          body: "It's your turn to play",
          vibrate: [200, 100, 200],
          icon: "/favicon.ico",
          tag: "turn",
          silent: false,
          // actions: [
          //   { action: 'play', title: 'Play' },
          //   { action: 'pass', title: 'Pass' },
          // ]
        });
        notification.onclick = () => {
          window.focus();
        };
      } else if (Notification.permission !== "denied") {
        // check if we've already shown the dialog and don't show it again
        if (localStorage.getItem("notifDialog") === "false") {
          return;
        }
        // show a UI prompt explaining to the user why they should allow notifications
        setNotifDialogOpen(true);
      } else {
        // if we don't have permission and the user has denied notifications then play a sound
        const audio = new Audio("/your-turn.mp3");
        audio.play();
      }
    }
  };

  // use effect to run when it's this player's turn and play a sound
  useEffect(() => {
    promptUserForTurn();
  }, [game?.status.nextPlayer, user?.uid]);


  return (
    <div className="game">
      <div className="grid-area header">
        {session && <GameHeader />}
      </div>
      {/* a meterial UI dialog explaining to the user why they should allow notifications */}
      <Dialog open={notifDialogOpen} onClose={() => setNotifDialogOpen(false)}>
        <div>
          <h1>Allow notifications</h1>
          <p>Notifications are used to alert you when it&#39s your turn</p>
          <div>
            <Button color="primary" onClick={acceptDialog}>Allow</Button>
            <Button color="secondary" onClick={denyDialog}>Deny</Button>
          </div>

        </div>
      </Dialog>
      <div className="grid-area controls" style={{display: "flex"}}>
        <Chat />
        <div style={{minWidth: "100%", scrollSnapAlign: "center"}}>
          {!notStarted && <Timeline /> }
        </div>
      </div>
      <div className="grid-area hand">
        <GameStatus />
        {notStarted && <Seats order={game.order} />}
        <Handv2 setSelectedCard={playCard} chooseEnd={chooseEnd} selectedCard={selectedCard} pass={pass}/>
      </div>
      <div className={`grid-area board-container ${!hideleft ? "offset-left" : ""} ${!hideright ? "offset-right" : ""}`} onDoubleClick={() => dbClickShrink(boardContRef.current)}>
        {["started", "ended"].includes(String(game?.status.stateName))  && 
        <> 
          {Number(game?.played.length)> 2 && <Domino vals={game?.played[0].numbers || []} customClass={`rotate-${getRotationAngle(
            game?.played || [],
            0
          )}`} click={()=> chooseEnd(game?.played[0].numbers || [])} />}
          <div ref={liveRef} className="board-scroller">
            {<Boardv2 chooseEnd={chooseEnd} selectedCard={selectedCard} />}
          </div>
          {Number(game?.played.length)> 2 && <Domino vals={game?.played.at(-1)?.numbers || []} click={() => chooseEnd(game?.played.at(-1)?.numbers || [])} customClass={`rotate-${getRotationAngle(
            game?.played || [],
            Number(game?.played.length) - 1
          )}`} />}
          <GoToTop style={{display: hideleft ? "none": ""}} size="small" onClick={() => boardContRef.current?.scrollTo({top: 0, behavior: "smooth", left: 0})}></GoToTop>
          <GoToBottom style={{display: hideright ? "none": ""}} size="small" onClick={() => boardContRef.current?.scrollTo({top: 10000, behavior: "smooth", left: 10000})}></GoToBottom>
        </>
        }{notStarted && <ShuffledPack />} 

      </div>
    </div>
  );
};

export default Gamev2;