import StartSession from "./Views/StartSession";
import { StartSessionv2 } from "./components/StatrtSessionv2/StartSessionv2";


function Main() {


  return (
    <div className="App">
      <StartSession />
      <StartSessionv2 />
    </div>
  );
}

export default Main;
