import { useContext, useState } from "react";
import { SessionContext } from "../contexts/sessionContext";
import { Domino } from "./Domino";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Alert, Snackbar } from "@mui/material";

export const ShuffledPack = () => {
  const { sessionId, pack, currentGameId, backendUrl, game, authData: {user}, session } = useContext(SessionContext);

  const [animationParent] = useAutoAnimate<HTMLDivElement>();
  
  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  const isSeated = session?.order.includes(String(user?.uid));
  const cardsLeft =
    pack && Number(game?.numberOfCards) - pack.filter(({ player }) => player === user?.uid).length;

  const takeCard = async (cardId: string) => {
    await fetch(`${backendUrl}/pullCard`, {
      method: "POST",
      body: JSON.stringify({
        cardId: cardId,
        gameId: currentGameId,
        sessionId,
        token: await user?.getIdToken(),
      })
    }).then(res => res.json());
  };
  return (
    <div>
      {isSeated ? <span>Take {cardsLeft} more cards</span>: <span>Take a seat before pulling</span>}
      <div ref={animationParent}>
        {pack &&
          pack.filter(card => card.player !== user?.uid).sort((a,b) => parseInt(a.id) - parseInt(b.id)).map(({ numbers, player, id }) => (
            <Domino
              key={`${numbers[0]}-${numbers[1]}`}
              vals={player && player === user?.uid ? numbers : []}
              gone={!!player && player !== user?.uid}
              click={() => {
                if(!isSeated){
                  return setOpen(true);
                } else if (!player) { 
                  takeCard(id);
                }
              }}
            />
          ))}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
    Get seated before pulling
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ShuffledPack;
