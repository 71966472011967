import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./Pages/Login/Login";
import Main from "./Main";
import { SessionProvider } from "./contexts/sessionContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Gamev2 from "./Views/Gamev2/Gamev2";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const App = () => {
  const auth = getAuth();
  
  
  onAuthStateChanged(auth, (user) => {
    if (!user && window.location.pathname !== "/login") {
      window.location.pathname = "/login";
    }
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}>
          </Route>
          <Route path="/game" element={<SessionProvider><Gamev2 /></SessionProvider>}>
          </Route>
          <Route path="/" element={<SessionProvider><Main /></SessionProvider>}>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;