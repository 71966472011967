// import { Button } from "@mui/material";
import Button from "@mui/material/Button";
import { SessionSummary } from "../../components/SessionSummary/SessionSummary";
// @ts-ignore
import {ReactComponent as HomeIcon} from "../../img/home.svg";
import { useContext } from "react";
import { SessionContext } from "../../contexts/sessionContext";
import { useNavigate } from "react-router-dom";

export const GameHeader =() => {

  const {setSessionId} = useContext(SessionContext);
  const nav = useNavigate();
  
  const goHome = async () => {
    setSessionId(null);
    nav("/");
  };
  return (<div className="game-header">
    <Button data-test="home-button" size="small" onClick={goHome}>
      <HomeIcon style={{width: "32px"}} />
    </Button>
    <div className="right-side">
      <SessionSummary />
    </div>
  </div>);
};