import { getApp } from "firebase/app";
import { getAuth, Unsubscribe } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Card, FECard, Game, Session, SessionWithId } from "../sessionContext";

type Params = {
  sessionId: string | null;
  userId?: string | null;
};
export function useConnectToSessionData({ sessionId, userId }: Params) {
  const [sessionUnsub, setsessionUnsub] = useState<Unsubscribe | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  // !! setting this allows us to not have to update current game subscription every time the sesison has som change
  const [currentGameId, setCurrentGameId] = useState<string | null>(null);
  const [sessions, setSessions] = useState<SessionWithId[]>([]);

  const user = getAuth().currentUser;

  useEffect(() => {
    const db = getFirestore(getApp());
    if (sessionId) {
      console.log("subscribing to", sessionId);
      // if the unsubscribe function exists for the session then make sure we run it
      sessionUnsub && sessionUnsub();
      const docRef = doc(collection(db, "sessions"), sessionId);
      const unsub = onSnapshot(
        docRef,
        (doc) => {
          const data = doc.data() as Session;
          if (data) {
            setSession(data);
            setCurrentGameId(data.currentGame);
          }
        },
        console.log
      );
      setsessionUnsub(() => unsub);
    }

    if (userId) {
      const sessionsRef = query(
        collection(db, "sessions"),
        where("participants", "array-contains", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      getDocs(sessionsRef).then((sessionsSnapshot) => {
        const sessions: SessionWithId[] = [];
        sessionsSnapshot.forEach((doc) => {
          sessions.push({ ...doc.data(), id: doc.id } as SessionWithId);
        });
        setSessions(sessions);
      });
    }

    return () => {
      sessionUnsub && sessionUnsub();
    };
  }, [sessionId, user]);

  return { session, currentGameId, sessions, sessionUnsub };
}

type CGProps = {
  currentGameId: string | null;
  userId: string | null;
};
export function useConnectToCurrentGameData({
  currentGameId,
  userId,
}: CGProps) {
  const [gameUnsub, setgameUnsub] = useState<Unsubscribe | null>(null);
  const [game, setGame] = useState<Game | null>(null);
  const [pack, setPack] = useState<FECard[] | null>(null);
  const [packUnsub, setpackUnsub] = useState<Unsubscribe | null>(null);

  useEffect(() => {
    const db = getFirestore(getApp());
    if (currentGameId) {
      gameUnsub && gameUnsub();
      const gameDocRef = doc(collection(db, "games"), currentGameId);
      const newgameunsub = onSnapshot(
        gameDocRef,
        (doc) => {
          const data = doc.data() as Game;
          if (data) {
            setGame(data);
            console.log(currentGameId, "Current data: ", doc.data());
          }
        },
        console.log
      );
      packUnsub && packUnsub();
      const packDocRef = collection(db, "games", currentGameId, "pack");
      const newpackunsub = onSnapshot(
        query(packDocRef, where("visibility", "in", ["all", userId])),
        (querySnapshot) => {
          const pack: FECard[] = [];
          querySnapshot.forEach((doc) => {
            pack.push({ ...(doc.data() as Card), id: doc.id });
          });
          setPack(pack);
          console.log(pack);
        },
        console.log
      );
      setpackUnsub(() => newpackunsub);
      setgameUnsub(() => newgameunsub);
    }
    return () => {
      gameUnsub && gameUnsub();
    };
  }, [currentGameId]);

  return { game, pack };
}

interface UIProps {
  ids?: string[];
}

export function useGetUsersInfo({ ids }: UIProps) {
  const db = getFirestore(getApp());
  const usersColRef = collection(db, "users");

  const [users, setUsers] = useState<Record<string, string>[] | null>(null);
  useEffect(() => {
    if (ids) {
      getDocs(query(usersColRef, where("uid", "in", ids)))
        .then((querySnapshot) => {
          const group: Record<string, string>[] = [];
          querySnapshot.forEach((doc) => {
            group.push({ ...doc.data(), id: doc.id });
          });
          setUsers(group);
          console.log(group);
        })
        .catch(console.log);
    }
    console.log(ids);
  }, [ids]);

  return users;
}
